export default {
  id: {
    label: 'id',
    disabled: true,
    valuesForId: ["name"],
    optional: true,
},
  name: {},
  website: {
    optional: true,
  },
  logoIsSvg: {
    render: 'checkbox',
    optional: true,
  },
  picture: {
    render: 'imagePicker',
    optional: true,
    props: {
      renameFileTo: (_, formData) => formData.id + '-logo',
      mode: 'sizes',
      sizes: [300, 600, 900, 1200],
      prefix: 'event/sponsors',
      authorizedExts: ['png', 'svg'],
    },
  },
};
