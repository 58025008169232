import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import ActionCreators from '../state/actions';
import {Layout, Menu, Icon, Modal, Input, Select} from 'antd';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';
import Event from './Event';
import Settings from './Settings';
import Video from './Video';
import SpeakersPage from './SpeakersPage';
import SeriePage from './SeriePage';
import Form from '../components/Form';
import logo from '../logo.png';
import {sortData} from '../utils/utils.js'
import './App.css';
const moment = require('moment-timezone');

const {Header, Sider, Content} = Layout;

class LayoutComponent extends Component {
  state = {
    displayNewSerie: false,
    displayNew: false,
    displayNewLoading: false,
  };

  onOk = event => {
    this.setState({
      displayNewLoading: false,
      displayNew: false,
    });

    window.location.replace(`/${event.city}/${event.year}`);
  };

  onOkSerie = serie => {
    this.setState({
      displayNewLoading: false,
      displayNewSerie: false,
    });

    // window.location.replace(`/${serie.city}/${serie.year}`);
  };

  onCreate = async () => {
    this.setState({displayNewLoading: true});

    const elem = await this.validate();

    if (!elem) {
      this.setState({
        displayNewLoading: false,
      });
    }
   this.onRefresh()
  };

  onRefresh = () => {
    this.props.actions.getAllCities();
    this.props.actions.getAllSeries()
    this.props.actions.getLoggedUser();
  }

  componentDidMount() {
    this.onRefresh()
  }

  getTimezone() {
    var result = [];
    result = moment.tz.names().map(val => {
      return ({name:val})
    })
    return result;
  }

  render() {
    const { series } = this.props
    const event = {
      city: {
        render: 'selector',
        props: {
          data: this.props.cities,
          config: {
            city: {
              label: 'id',
              disabled: true,
              valuesForId: ["cityName"],
              optional: true
            },
            cityName: {
              label: 'Name',
            },
            locale: {
              render: (
                <Select>
                  <Select.Option value="en_US">English</Select.Option>
                  <Select.Option value="fr_FR">French</Select.Option>
                </Select>
              ),
            },
            timezone: {
              render: "selector",
              props: {
                data : this.getTimezone(),
                valueField: 'name',
                displayField: 'name',
              }
            },
            countryName: {
              label: 'Country Name',
            },
            lastYear: {
              label: 'Last Event Year (will be auto-filled)',
              isNumber: true,
              render: <Input type='number' />,
            },
          },
          valueField: 'city',
          displayField: 'cityName',
          default: this.props.event && this.props.event.city,
          endpoint: '/api/cities'
        },
      },
      year: {
        isNumber: true,
        render: <Input type='number' />,
      },
    };
    const serie = {
      name: {
          render: <Input type='text'/>,
      },
      description: {
        render: <Input.TextArea />,
      },
      url: {
        render: <Input type='text'/>,
      },
      language: {
        render:
            <Select initialValue="none">
              <Select.Option value="fr">FR</Select.Option>
              <Select.Option value="en">EN</Select.Option>
              <Select.Option value="es">ES</Select.Option>
              <Select.Option value="none" disabled>None</Select.Option>
            </Select>
      },
      year: {
        isNumber: true,
        render: <Input type='number' />,
      },
    };
    return (
      <Router>
        <Layout className='Layout'>
          <Header className='header'>
            <div className='logo'>
              <img src={logo} style={{maxHeight: 30, marginRight: 25}} alt='logo' />
              <b>Vintage Report</b> Admin
              <div style={{float: 'right'}}>
                {!this.props.user ? <Icon type='loading-3-quarters' spin /> : (
                  <span>
                    <Icon type="user" />
                    <span> {this.props.user.email}</span>
                  </span>
                )}
              </div>
            </div>
          </Header>
          <Layout>
            <Sider width={200}>
              <Menu
                mode='inline'
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                style={{height: '100%', borderRight: 0}}
              >
                {
                  this.props.cities && sortData(this.props.cities, 'city').map(city => (
                    <Menu.SubMenu key={city.city} title={<span>{city.cityName}</span>}>
                      {
                        city.editions.map(year => (
                          <Menu.Item key={`${city.city}-${year}`}>
                            <Link to={`/event/${city.city}/${year}`}>{year}</Link>
                          </Menu.Item>
                        ))
                      }
                    </Menu.SubMenu>
                  ))
                }
                <Menu.Item onClick={() => this.setState({displayNew: true})} style={{marginTop: 20}}>
                  <Icon type='plus' /> New event
                </Menu.Item>
                <Menu.SubMenu title="Series">
                   {
                     series && series.map(serie => (
                           <Menu.Item key={`${serie._id}`}>
                             <Link to={`/series/${serie._id}`}>{serie.name}</Link>
                           </Menu.Item>
                       ))
                    }
                  <Menu.Item key={`${serie.name}`} onClick={() => this.setState({displayNewSerie: true})}>
                    <Icon type='plus' /> Create new
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item>
                  <Link to={`/video`}>
                    <Icon type='video-camera' /> Video
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={`/speakers`}>
                    <Icon type='team' /> Speakers
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={`/`}>
                    <Icon type='setting' /> Other
                  </Link>
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout style={{padding: '0 24px 24px'}}>
              <Content style={{padding: 24, margin: 0, minHeight: window.outerHeight}}>
                <Route exact path='/event/:city/:year' component={Event} />
                <Route exact path='/series/:id' component={() => <SeriePage onDelete={this.onRefresh} />} />
                <Route exact path='/video' render={Video} />
                <Route exact path='/speakers' render={SpeakersPage} />
                <Route exact path='/' render={Settings} />
              </Content>
            </Layout>
            <Modal
                visible={this.state.displayNew}
                onOk={this.onCreate}
                onCancel={() => this.setState({displayNew: false})}
                confirmLoading={this.state.displayNewLoading}
            >
              <Form
                  endpoint='/api/events'
                  payload={{}}
                  config={event}
                  substituteValidation={ref => this.validate = ref}
                  postSubmit={this.onOk}
              />
            </Modal>
            <Modal
                visible={this.state.displayNewSerie}
                onOk={this.onCreate}
                onCancel={() => this.setState({displayNewSerie: false})}
                confirmLoading={this.state.displayNewLoading}
            >
              <Form
                  endpoint='/api/series'
                  payload={{}}
                  config={serie}
                  substituteValidation={ref => this.validate = ref}
                  postSubmit={this.onOkSerie}
              />
            </Modal>
          </Layout>
        </Layout>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    cities: state.data.cities,
    user: state.data.user,
    series: state.data.series
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
