import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import ActionCreators from '../state/actions';
import {Form, Button, Input, message, Switch, Icon, Modal} from 'antd';
import Selector from './Selector';
import FormItem from './FormItem';
import FilePicker from './FilePicker'
import stringToStringURL from '../services/convertStringToStringURL'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import {fr, enUS} from 'date-fns/locale';
import { includes, isNil, replace } from "ramda";

const moment = require('moment-timezone');


const USFormatDate = {
	formatMoment:'MM/DD/YYYY h:mm a',
	localeRegister:enUS,
	formatDatePicker:'MM/d/yyyy h:mm a',
	formatTimePicker:'h:mm a'
}
const FRFormatDate = {
	formatMoment:'DD/MM/YYYY H:mm',
	localeRegister:fr,
	formatDatePicker:'d/MM/yyyy H:mm',
	formatTimePicker:'H:mm'
}


class FormComponent extends Component {

	getSelectSpeaker(props = this.props) {
		const { speaker, speakers } = props.payload
		const selectedSpeaker = speaker || speakers
		if (selectedSpeaker) {
			return selectedSpeaker.length === undefined ? [selectedSpeaker.id] : typeof selectedSpeaker === "string" ? [selectedSpeaker] : selectedSpeaker.map(data => typeof data === "object" ? data.id : data);
		}
		return []
	}

	state = {
		form: this.props.config,
		dataNameSpeaker: this.props.config["speaker"],
		selectSpeaker: this.getSelectSpeaker(),
		production: this.props.payload["production"],
		img: this.props.payload["img"]
	}

	selectors = [];

	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			error: false,
			paramDateTime: this.getParamDateTime(props),
		}
		this.multiSelectorChanged = this.multiSelectorChanged.bind(this)
	}

	hasErrors = fieldsError => {
		return this.state.error || Object.keys(fieldsError).some(field => fieldsError[field]);
	}

	sendToApi = payload => {
		if (this.props.wrapInto) {
			const k = Object.keys(this.props.wrapInto).find(key => this.props.wrapInto[key] === '$val')
			if (k === '$this') {
				payload = {
					...this.props.wrapInto,
					...payload,
				};
				delete payload.$this;
			} else {
				payload = {
					...this.props.wrapInto,
					[k]: payload,
				};
			}
		}
		this.props.actions.ajaxStart({
			method: 'post',
			endpoint: this.props.endpoint,
			body: payload,
			loadingMessage: this.props.loadingMessage || this.props.loadingMessage !== null,
			successMessage: this.props.successMessage || this.props.successMessage !== null,
			callback: this.props.postSubmit && this.props.postSubmit.bind(null, payload, true, !(this.props.isEditing === undefined || this.props.isEditing === false))
		});
	}

	handleSubmit = async e => {
		if (e) {
			e.preventDefault();
		}

		return await new Promise((resolve, reject) => {
			this.props.form.validateFields((err, values) => {
				if (!err) {

					this.setState({error: false});
					for (let field in values) {
						if (values[field] === undefined && !this.state.form[field].value && !this.state.form[field].optional && this.state.form[field].render !== 'checkbox' && this.state.form[field].render !== 'productionReady') {
							this.setState({error: true});
							message.error('Please fix the form issues.');
							return;
						} else if (this.state.form[field].value) {
							values[field] = this.state.form[field].value;
						} else if (this.state.form[field].isNumber) {
							values[field] = +values[field];
						} else if (this.state.form[field].render === 'id' || this.state.form[field].label === 'id') {
							if (this.state.form[field].valuesForId) {
								try {
									var id = "";
									this.state.form[field].valuesForId.forEach(val => (id += values[val]))
									id = stringToStringURL(id);
									values[field] = id;
								} catch (e) {
									console.error("error creation id");
								}
							} else {
								values[field] = this.props.valueId;
							}
						} else if (field === "timestamp") {
							if (typeof values.timestamp === "string" || typeof values.timestamp === "object") {
								values[field] = moment(values.timestamp).format("X");
							} else
								values[field] = this.props.payload.city.timestamp
						} else if (field === 'date') {
							values[field] = moment(values[field]).valueOf()
						} else if (field === "img" && !isNil(this.state.img)) {
							values[field] = this.state.img;
						}
					}
					if (!this.props.disableApiValidation) {
						this.sendToApi(values);
					} else {
						this.props.postSubmit(values);
					}
					return resolve(values);
				}
				if (!err) {
					this.props.form.resetFields();
				}
				resolve(false);
			});
		});
	}

	selectorChanged = (id, value) => {
		const f = this.state.form;
		if (value == null) {
			f[id].value = value;
			f[id].data = value;
		} else {
			f[id].value = value ? value[f[id].props.valueField] : '';
			f[id].data = value;
		}
		this.setState({
			form: f,
			error: false,
		});
	};

	multiSelectorChanged = (key, value, id) => {
		var f = this.state.form;

		if (value == null) {
			var data = this.state.selectSpeaker;
			data.splice(id, 1);
			this.setState({selectSpeaker: data})

			if (this.state.selectSpeaker === []) {
				f[key].value = [];
				f[key].data = [];
			} else {
				f[key].value = data;
				f[key].data = data;
			}
		} else {
			if (this.state.selectSpeaker.length !== 0) {
				if (this.state.selectSpeaker.indexOf(value[f[key].props.valueField]) === -1) {
					const { speaker, speakers } = this.props.payload
					if (speaker !== undefined || speakers !== undefined) {
						f[key].value = value ? [...this.state.selectSpeaker, value[f[key].props.valueField]] : [''];
						if (speaker !== undefined) {
							f[key].data = [...speaker, value];
						} else {
							f[key].data = [...speakers, value];
						}
						this.setState({selectSpeaker: [...this.state.selectSpeaker, value[f[key].props.valueField]]})
					} else {
						f[key].value = value ? [...this.state.selectSpeaker, value[f[key].props.valueField]] : [''];
						f[key].data = [...f[key].data, value];
						this.setState({selectSpeaker: [...this.state.selectSpeaker, value[f[key].props.valueField]]})
					}
				}
			} else {
				f[key].value = value ? [value[f[key].props.valueField]] : [''];
				f[key].data = [value];
				this.setState({selectSpeaker: [value[f[key].props.valueField]]})
			}
		}

		this.setState({
			form: f,
			error: false,
		});
	};

	componentDidMount() {
		registerLocale("fr", fr);
		if (typeof this.props.substituteValidation === 'function') {
			this.props.substituteValidation(this.handleSubmit, this.postSubmit);
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.config) {
			this.setState({
				form: {
					...this.state.form,
					...props.config,
				},
				selectSpeaker: this.state.selectSpeaker?.length < 1 ? this.getSelectSpeaker(props) : this.state.selectSpeaker,
			});
		}
	}

	getSelectorName(fieldProps) {
		var result = "";
		if (fieldProps["initialValue"] && fieldProps.key !== "speaker")
			return(fieldProps["initialValue"]);
		if (!this.state.dataNameSpeaker || !this.state.dataNameSpeaker["props"] || !this.state.dataNameSpeaker["props"]["data"])
			return (result);
		this.state.dataNameSpeaker["props"]["data"].forEach((data, key) => {
			if (data["id"] === fieldProps["initialValue"]) {
				result = data["name"];
			}
		});
		return(result);
	}

	onDelete(field) {
		Modal.confirm({
			title: 'Are you sure?',
			content: `You will delete the ${field.label} of ${this.props.payload.city.city} ${this.props.payload.year} event.`,
			onOk: () => {
				field.props.onDelete(field.props.data.id)
			},
			onCancel() {},
		});
	}

	handleDate = date => {
		this.setState({
			startDate: date
		});
	}

	getParamDateTime = (props = null) => {
		var result = {};
		if ((this.props && this.props.payload && this.props.payload.city) || (props && props.payload && props.payload.city)) {
			var city = this.props && this.props.payload && this.props.payload.city ? this.props.payload.city : props.payload.city;
			var locale = city.locale;
			switch (locale) {
				case 'fr_FR':
					result = {timeZone:city.timezone, formatMoment:'DD/MM/YYYY H:mm', localeRegisterTitle: "fr", localeRegister:fr, formatDatePicker:'d/MM/yyyy H:mm', formatTimePicker:'H:mm'};
					break;
				default:
					result = {timeZone:city.timezone, formatMoment:'MM/DD/YYYY h:mm a', localeRegisterTitle: "enUS", localeRegister:enUS, formatDatePicker:'MM/d/yyyy h:mm a', formatTimePicker:'h:mm a'};
			}
			registerLocale(result.localeRegisterTitle, result.localeRegister);
			return (result);
		}
	}

	multiSelectionDisplay(field, key, fieldProps)
	{
		if (!fieldProps.initialValue && !this.state.selectSpeaker)
			return null;
		let data = []
		data = this.state.selectSpeaker;
		let table = [];
		for (let i = 0; i < data.length ; i++) {
			if (data[i]) {
				let children = []
				children.push(<td key={i}>
					<span>
						{typeof data[i] === "string" ? data[i] : data[i].name}
					</span>
					<Button key={i} style={{marginLeft: 5}} type='danger'
							onClick={() => this.multiSelectorChanged(key, null, i)}
							>
						<Icon type='delete'/>
					</Button>
				</td>)
				table.push(<tr key={i}>{children}</tr>)
			}
		}
		return (
			table
		);
	}

	onToggleProduction = key => {
		this.setState({
			production: !this.state.production
		})
	}

	onSuccessImageUpload = img => {
		const fileName = replace('.jpg', '', img?.file?.name)
		this.setState({
			img: fileName
		})
	}

	render() {
		const selectorError = id => this.state.error || ('value' in this.state.form[id] && !this.state.form[id].value && !this.state.form[id].optional);
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0,
				},
				sm: {
					span: 16,
					offset: 8,
				},
			},
		};
		return (
			<div>
				<Form onSubmit={this.handleSubmit}>
					{Object.keys(this.state.form).map(key => {
						const field = this.state.form[key];
						const fieldProps = {
							_key: key,
							key,
							field,
							selectorError,
							form: this.props.form,
							initialValue: this.props.payload && this.props.payload[key],
						};
						if (field.render === 'selector') {
							return (
								<FormItem
									validateStatus={selectorError(key) ? 'error' : ''}
									help={selectorError(key) ? field.help || `${key} is required` : ''}
									optional="true"
									{...fieldProps}
								>
								<span>
									<Input
										readOnly
										value={field.value}
										style={{display: 'none'}}
									/>
									<span>
									{
										field.data !== undefined ? field.data && field.data[field.props.displayField]
											: this.props.payload && this.props.payload[key] && this.props.payload[key][field.props.displayField]
									}
									{(field.data && field.data[field.props.displayField]) === undefined &&
									(this.props.payload && this.props.payload[key] && this.props.payload[key][field.props.displayField]) === undefined
										? this.getSelectorName(fieldProps) : null}
									</span>
									<Button style={{marginLeft: 20}} onClick={() => this.selectors[key].show()}>
										<Icon type='edit'/> Edit
									</Button>
									<Button style={{marginLeft: 5}} type='danger'
											onClick={() => this.selectors[key].clear()}>
										<Icon type='delete'/>
									</Button>
									<Selector
										_ref={ref => this.selectors[key] = ref}
										id={key}
										value={this.selectorChanged}
										item={this.props.payload}
										{...field.props}
									/>
								</span>
								</FormItem>
							);
						} else if (field.render === 'multiSelector') {
							return (
								<FormItem
									validateStatus={selectorError(key) ? 'error' : ''}
									help={selectorError(key) ? field.help || `${key} is required` : ''}
									optional="true"
									{...fieldProps}
								>
								<span>
									<table>
									<tbody>
										<tr>
											<td>
												<Button style={{marginLeft: 20}} onClick={() => this.selectors[key].show()}>
													<Icon type='edit'/> Edit
												</Button>
											</td>
										</tr>
										{this.multiSelectionDisplay(field, key, fieldProps)}
									</tbody>
									</table>
									<Selector
										_ref={ref => this.selectors[key] = ref}
										id={key}
										value={this.multiSelectorChanged}
										item={this.props.payload}
										{...field.props}
									/>
								</span>
							</FormItem>
							);
						} else if (field.render === 'imagePicker') {
							return (
								<FormItem {...fieldProps} optional={"true"}>
									<FilePicker apiRoute='/api/images' onSuccess={field.hasUpdateName ? this.onSuccessImageUpload : undefined} {...field.props} form={this.props.form} href={this.props.payload?.img}/>
								</FormItem>
							);
						} else if (field.render === 'pdfPicker' && this.props.payload !== undefined) {
							if (this.props.payload["isPassed"]) {
								return (
									<FormItem {...fieldProps} optional={"true"}>
										<FilePicker apiRoute='/api/pdf' {...field.props} form={this.props.form}/>
									</FormItem>
								);
							}
						} else if (field.render === 'checkbox') {
							return (
								<FormItem {...fieldProps} optional={"true"}>
									<Switch checked={this.props.payload && this.props.payload[key]} onClick={() => this.onToggle(key)} />
								</FormItem>
							);
						} else if (field.render === 'productionReady') {
							return (
								<FormItem {...fieldProps} optional={"true"}>
									<Switch checked={this.state.production} onClick={this.onToggleProduction} />
								</FormItem>
							);
						} else if (field.render === 'hidden') {
							return (
								<span/>
							);
						} else if (field.render === 'datePicker') {
							var paramDate = this.getParamDateTime();
							var dateTime = moment.tz(this.props.payload.timestamp * 1000, paramDate.timeZone).locale(paramDate.timeZone);
							return (
								<FormItem {...fieldProps} extensionTitle={` (${this.state.paramDateTime && this.state.paramDateTime.localeRegisterTitle ? this.state.paramDateTime.localeRegisterTitle : paramDate.timeZone})`} initialValue={dateTime.isValid() ? dateTime.format(paramDate.formatMoment) : null}>
									<DatePicker
										showTimeSelect
										timeIntervals={5}
										onChange={this.handleDate}
										selected={this.state.startDate}
										placeholderText={this.state.paramDateTime && this.state.paramDateTime.formatDatePicker ? this.state.paramDateTime.formatDatePicker : paramDate.formatDatePicker}
										timeFormat={this.state.paramDateTime && this.state.paramDateTime.formatTimePicker ? this.state.paramDateTime.formatTimePicker : paramDate.formatTimePicker}
										dateFormat={this.state.paramDateTime && this.state.paramDateTime.formatDatePicker ? this.state.paramDateTime.formatDatePicker : paramDate.formatDatePicker}
										locale={this.state.paramDateTime && this.state.paramDateTime.localeRegisterTitle ? this.state.paramDateTime.localeRegisterTitle : paramDate.localeRegisterTitle}
									/>
								</FormItem>
							);
						} else if (field.render === 'datePickerCurrentTime') {
							const { date } = this.props.payload
							const timeZone = moment.tz.guess();
							const paramDate = includes('Europe', timeZone) ? FRFormatDate : USFormatDate
							const datePickerValue = !!date && moment(date).isValid()
								? moment(date).tz(timeZone).format(paramDate.formatMoment)
								: moment().tz(timeZone).format(paramDate.formatMoment)

							return (
								<FormItem {...fieldProps} size="large" extensionTitle={` ${timeZone}`} initialValue={datePickerValue}>
									<DatePicker
										showTimeSelect
										timeIntervals={5}
										onChange={this.handleDate}
										selected={this.state.startDate}
										placeholderText={paramDate.formatDatePicker}
										timeFormat={paramDate.formatTimePicker}
										dateFormat={paramDate.formatDatePicker}
										locale={paramDate.localeRegister}
									/>
								</FormItem>
							);
						} else {
							return (
								<FormItem {...fieldProps}>
									{field.render || <Input disabled={field.disabled ? field.disabled : false } type='text' />}
								</FormItem>
							);
						}
						return ("");
					})}
					{this.props.substituteValidation ? null : (
						<Form.Item {...tailFormItemLayout}>
							<Button
								type="primary"
								htmlType="submit"
							>
								Save
							</Button>
						</Form.Item>
					)}
				</Form>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
	};
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(FormComponent));
