import React, {useEffect, useRef, useState} from 'react'
import {Col, Input} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {remove, insert, update, move} from 'ramda'
import List from '../../components/List';
import ActionCreators from '../../state/actions';

const Webinars = ({serie}) => {
    const dispatch = useDispatch()
    const [modifiedWebinars, setModifiedWebinars] = useState(serie?.webinars)
    const firstLoad = useRef(true)
    const { speakers } = useSelector(state => state.data)

    useEffect(() => {
        dispatch(ActionCreators.getAllSpeakers())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setModifiedWebinars(serie.webinars)
    }, [serie])
    
    useEffect(() => {
        if (firstLoad.current === false) {
            dispatch(ActionCreators.ajaxStart({
                method: 'post',
                endpoint: '/api/series',
                body: {...serie, webinars: modifiedWebinars},
                loadingMessage: true,
                successMessage: true,
            }));
        } else {
            firstLoad.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modifiedWebinars])

    const addWebinar = (newWebinar, updateIndex) => {
        if (!newWebinar) {
            return
        }
        if (typeof updateIndex !== 'number') {
            setModifiedWebinars(insert(0, newWebinar, modifiedWebinars || []))
        } else {
           setModifiedWebinars(update(updateIndex, newWebinar, modifiedWebinars))
        }
    }

    const deleteWebinar = (index) => {
        if (index > -1 && modifiedWebinars) {
            setModifiedWebinars(remove(index, 1, modifiedWebinars))
        }
    }

    const swapUp = (index) => {
        if (index > 0) {
            setModifiedWebinars(move(index, index - 1, modifiedWebinars))
        }
    }
    const swapDown = (index) => {
        if (index < modifiedWebinars.length) {
            setModifiedWebinars(move(index, index + 1, modifiedWebinars))
        }
    }

    const config = {
        production: {
            label: 'Display to public',
            render: 'productionReady',
        },
        title: {},
        description: {
            render: <Input.TextArea />,
            optional: 'true',
        },
        date: {
            render: 'datePickerCurrentTime',
        },
        img: {
            render: 'imagePicker',
            hasUpdateName: true,
            optional: 'true',
            label: 'Image',
            props: {
                mode: 'sizes',
                sizes: [400],
                prefix: `serie`,
                authorizedExts: ['jpg'],
                fileUpload: {
                    name: `webinar img`,
                }
            },
        },
        ticketing: {
            optional: 'true',
        },
        speaker: {
            optional: 'true',
            render: 'multiSelector',
            props: {
                data: speakers,
                valueField: 'id',
                displayField: 'name',
            },
        }
    }

    return (
        <Col offset={6} span={12}>
            <List
                dataSource={modifiedWebinars}
                displayField='title'
                onItemClick={console.info}
                config={config}
                name='Webinar'
                disableApiValidation={true}
                onOk={addWebinar}
                onDelete={deleteWebinar}
                swapUp={swapUp}
                swapDown={swapDown}
                mainPage={true}
            />
        </Col>
    )
}

export default Webinars
