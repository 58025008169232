import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import ActionCreators from '../state/actions';
import {Input, Icon, Button, Modal} from 'antd';
import Form from '../components/Form';

class GeneralComponent extends Component {

  state = {
    cities : []
  };

  onDelete = () => {
    Modal.confirm({
      title: 'Are you sure?',
      content: `You will delete the ${this.props.event.city.cityName} ${this.props.event.year} event.`,
      onOk: () => {
        this.props.actions.ajaxStart({
          method: 'delete',
          endpoint: `/api/events/${this.props.event.city.city}/${this.props.event.year}`,
          loadingMessage: this.props.loadingMessage || this.props.loadingMessage !== null,
          successMessage: this.props.successMessage || this.props.successMessage !== null,
          callback: () => this.props.onDelete(),
        });
      },
      onCancel() {},
    });
  };

  renameFilePdf = () => {
    if (this.props.event.city.locale === 'fr_FR') {
      return `compte-rendu-conference-du-millesime-${this.props.event.city.city}-${this.props.event.year}`;
    } else {
      return `summary-vintage-report-${this.props.event.city.city}-${this.props.event.year}`;
    }

  }

  onBunchUploadPdf = info => {
    const filenameSplit = info.file.name.split('.');
    const ext = filenameSplit.pop();
    const fullname = `${this.props.event.city.city}/${this.props.event.year}/${this.renameFilePdf()}.${ext}`;
    const pdf = {
      uri: fullname,
      title: `Vintage Report ${this.props.event.city.cityName} ${this.props.event.year}`,
    };

    this.props.actions.ajaxStart({
      method: 'post',
      endpoint: '/api/events/pdf',
      body: {
        city: this.props.event.city.city,
        year: this.props.event.year,
        pdf,
      },
      loadingMessage: true,
    });
  }

  getLayoutData() {
   let result;
    if (this.props.event && this.props.event["pdf"]) {
      const nameFile = this.props.event["pdf"].map((data, id) => {
        if (data["src"] && data["src"].indexOf(`event/${this.props.event.city.city}/${this.props.event.year}/${this.renameFilePdf()}`) !== -1) {
          return ({id: id, name: this.renameFilePdf(), href: data.src});
        }
        return ("");
      })
      result = nameFile.find(v => v.id !== undefined)
    }
    return (result);
  }

  sendPdfToApi = pdf => {
    const pdfForApi = pdf.map(p => ({
      uri: p.uri,
      title: p.title,
    }));

    this.props.actions.ajaxStart({
      method: 'post',
      endpoint: '/api/events',
      body: {
        city: this.props.event.city.city,
        year: this.props.event.year,
        pdf: pdfForApi,
      },
      loadingMessage: true,
    })
  }

  deletePdf = async data => {
      let pdf = this.props.event.pdf;
       pdf= [
          ...pdf.slice(0, data.id),
          ...pdf.slice(data.id + 1),
        ];
      this.sendPdfToApi(pdf);
  }

  render() {
    const form = {
      production: {
        label: 'Display to public',
        render: 'checkbox',
      },
      address: {},
      timestamp: !this.props.event ? {} : {
        render: 'datePicker',
        data: this.props.event.timeStamp ? this.props.event.timeStamp : undefined,
      },
      timeLine: {
        label: 'Display event on homepage timeline',
        render: 'checkbox',
      },
      description: {
        render: <Input.TextArea />,
      },
      picture: !this.props.event ? {} : {
        render: 'imagePicker',
        optional: true,
        label: 'Background header image',
        props: {
          renameFileTo: () => `background-header-${this.props.event.city.city}`,
          showOverwriteWarning: true,
          mode: 'sizes',
          sizes: [640, 1024, 1280, 1440, 2000],
          prefix: `event/${this.props.event.city.city}`,
          authorizedExts: ['jpg'],
          fileUpload: {
            name: `background-header-${this.props.event.city.city}`,
            href: this.props.event["backGroundImage"],
          }
        },
      },
      mapImg: !this.props.event ? {} : {
        render: 'imagePicker',
        optional: true,
        label: 'Map image',
        props: {
          renameFileTo: () => `map-${this.props.event.city.city}`,
          showOverwriteWarning: true,
          mode: 'sizes',
          sizes: [600, 1000, 1440],
          prefix: `event/${this.props.event.city.city}`,
          authorizedExts: ['jpg'],
          fileUpload: {
              name: `map-${this.props.event.city.city}`,
              href: this.props.event["mapImg"],
          }
        },
      },
      isPassed: {
        label: 'Use past layout',
        render: 'checkbox',
      },
      layout: !this.props.event ? {} : {
        render: 'pdfPicker',
        optional: true,
        label: 'PDF layout',
        props: {
          showOverwriteWarning: true,
          renameFileTo: this.renameFilePdf,
          prefix: `event/${this.props.event.city.city}/${this.props.event.year}`,
          authorizedExts: ['pdf'],
          onSuccess: this.onBunchUploadPdf,
          hint: 'Pdf are processed asynchronously so will not appear unless you refresh after a few seconds.',
          fileUpload: {
            displayPicture: false,
            ...this.getLayoutData(),
            onDelete: this.deletePdf
          }
        },
      },
    };

    return (
      <div>
        <Form
          endpoint='/api/events'
          payload={this.props.event || {}}
          config={form}
          wrapInto={{
            city: this.props.event && this.props.event.city.city,
            year: this.props.event && this.props.event.year,
            $this: '$val',
          }}
        />
        <Button style={{float: 'right'}} type='danger' onClick={this.onDelete}>
          <Icon type='delete' /> Delete this event
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cities: state.data.cities,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralComponent);
