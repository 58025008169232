import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import ActionCreators from '../state/actions';
import {Col, Button, Icon} from 'antd';
import List from '../components/List';
import Selector from '../components/Selector';
import Partner from '../constants/Partner';
import {sortData} from '../utils/utils.js';

class PartnersComponent extends Component {

  state = {
    partners: this.props.event.partners || [],
  };

  componentDidMount() {
    this.props.actions.getAllPartners();
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.event) {
      this.setState({partners: props.event.partners || []});
    }
  }

  sendToApi = (payload, callback) => {
    this.props.actions.ajaxStart({
      method: 'post',
      endpoint: '/api/events',
      body: {
        ...payload,
        city: this.props.event.city.city,
        year: this.props.event.year,
      },
      loadingMessage: true,
      successMessage: true,
      callback,
    });
  }

  addPartner = async (_, partner, __, update) => {
    if (!partner) {
      return;
    }

    if (!update || update === undefined)
      await this.setState({partners: [...this.state.partners, partner]})
    this.sendToApi({
      partners: this.state.partners.map(p => p.id),
    }, () => {
      this.props.actions.getAllPartners();
      this.props.actions.getEvent(this.props.event.city.city, this.props.event.year);
    });
  };

  deletePartner = (index) => {
    this.state.partners.splice(index, 1);
    this.sendToApi({
      partners: this.state.partners.map(p => p.id),
    });
  };

  render() {
    return (
      <Col offset={6} span={12}>
        <List
          dataSource={sortData(this.state.partners)}
          displayField='name'
          onItemClick={console.info}
          name='partner'
          onDelete={this.deletePartner}
        />
        <Button onClick={() => this.selector.show()} style={{marginTop: 10}}>
          <Icon type='plus' /> Add or edit partner
        </Button>
        <Selector
          endpoint='/api/partners'
          _ref={ref => this.selector = ref}
          value={this.addPartner}
          data={sortData(this.props.partners)}
          config={Partner}
          valueField='id'
          displayField='name'
          id='partner'
        />
      </Col>
    );
  }
}

const mapStateToProps = state => {
  return {
    partners: state.data.partners,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnersComponent);
