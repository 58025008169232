import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import ActionCreators from '../state/actions';
import {Select} from 'antd';
import Form from '../components/Form';

class TicketingComponent extends Component {

  componentDidMount() {
  }

  render() {
    const form = {
      open: {
        label: 'Sales open',
        render: 'checkbox',
      },
      service: {
        render: (
          <Select>
            <Select.Option value="eventBrite">EventBrite</Select.Option>
            <Select.Option value="weezEvent">WeezEvent</Select.Option>
          </Select>
        ),
      },
      id: {
        label: 'Event id',
      },
    };

    return (
      <div>
        <Form
          endpoint='/api/events'
          payload={this.props.event.ticketing || {}}
          wrapInto={{
            city: this.props.event.city.city,
            year: this.props.event.year,
            ticketing: '$val',
          }}
          config={form}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketingComponent);
