import React, {Component} from 'react';
import {message, Icon, Upload, Modal} from 'antd';
import axios from 'axios';
import FileRender from "./FilePickerRender";

class FilePickerComponent extends Component {

    upload(options) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(options.file);
        reader.onload = async () => {
            const config = {
                withCredentials: true,
                headers: {
                    'content-type': 'application/json',
                },
            };
            try {
                let filename = options.file.name.split('.');
                const ext = filename.pop();
                if (!this.props.authorizedExts.includes(ext)) {
                    options.onError(new Error('invalid file format, valids are: ' + this.props.authorizedExts.join(', ')));
                    return;
                }
                filename = filename.join('').replace(/ /g, '');
                if (typeof this.props.renameFileTo === 'function') {
                    filename = this.props.renameFileTo(filename, this.props.form && this.props.form.getFieldsValue());
                }
                const res = await axios.post(options.action, {
                    name: `${this.props.prefix}/${filename}`,
                    ext,
                    sizes: this.props.sizes,
                    mode: this.props.mode,
                    data: Buffer.from(reader.result).toString('base64'),
                }, config);
                options.onSuccess(res.data, options.file);
            } catch (e) {
                options.onError(e);
            }
        };
    }

    dragger = {
        multiple: true,
        action: `${process.env.REACT_APP_API_URL}${this.props.apiRoute}`,
        customRequest: (options) => {
            if (this.props["showOverwriteWarning"]) {
                Modal.confirm({
                    title: 'Are you sure?',
                    content: `You're going to overwrite the old file`,
                    onOk: () => {
                        this.upload(options)
                    },
                    onCancel() {
                    },
                });
            } else {
                this.upload(options);
            }
        },
        onChange: (info) => {
            const status = info.file.status;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                this.props.onSuccess && this.props.onSuccess(info);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed: ${info.file.error}`);
            }
        },
    };

    render() {
        return (
            <div>
                <Upload.Dragger {...this.dragger}>
                    <p className='ant-upload-drag-icon'>
                        <Icon type='inbox' />
                    </p>
                    <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                    <p className='ant-upload-hint'>{this.props.hint}</p>
                </Upload.Dragger>
                {this.props["fileUpload"] ? <FileRender href={this.props?.href} name={this.props?.name} { ...this.props["fileUpload"]} /> : null}
            </div>
        );
    }
}

export default FilePickerComponent;
