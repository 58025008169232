import keyMirror from 'key-mirror';

const ActionsTypes = keyMirror({
  SETUP_APP: null,
  AJAX_START: null,
  AJAX_DONE: null,
  AJAX_SUCCEEDED: null,
  AJAX_FAILED: null,
  GET_EVENT: null,
  GET_SERIE: null,
  GET_ALL_CITIES: null,
  GET_ALL_SPEAKERS: null,
  GET_ALL_PARTNERS: null,
  GET_LOGGED_USER: null,
  GET_CONFIG: null,
  GET_ALL_SERIES: null,
  DELETE_SERIE: null
});

export default ActionsTypes;
