import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import ActionCreators from '../state/actions';
import {Icon, Tabs} from 'antd';
import General from './General';
import Agenda from './Agenda';
import Speakers from './Speakers';
import Sponsors from './Sponsors';
import Partners from './Partners';
import Ticketing from './Ticketing';
import Photos from './Photos';
import './App.css';

class EventComponent extends Component {

  componentDidMount() {
    this.props.actions.getEvent(this.props.match.params.city, this.props.match.params.year);
  }

    UNSAFE_componentWillReceiveProps(props) {
    if (!props.match.params) {
      return;
    }

    const { city, year } = props.match.params;
    if (city !== this.props.match.params.city || year !== this.props.match.params.year) {
      this.props.actions.getEvent(city, year);
    }
  }

  render() {
    return (
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={<span><Icon type="setting" />General</span>} key="1">
          <General event={this.props.event} onDelete={() => this.props.history.push('/')}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span><Icon type="schedule" />Agenda</span>} key="2">
          <Agenda event={this.props.event} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span><Icon type="contacts" />Speakers</span>} key="3">
          <Speakers event={this.props.event} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span><Icon type="dollar" />Sponsors</span>} key="4">
          <Sponsors event={this.props.event} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span><Icon type="solution" />Partners</span>} key="5">
          <Partners event={this.props.event} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span><Icon type="barcode" />Ticketing</span>} key="6">
          <Ticketing event={this.props.event} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span><Icon type="picture" />Photos</span>} key="7">
          <Photos event={this.props.event} />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

const mapStateToProps = state => {
  return {
    event: state.data.event,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventComponent));
