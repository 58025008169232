import ActionsTypes from '../../constants/ActionsTypes';

const app = {
  setupApp: () => ({
    type: ActionsTypes.SETUP_APP,
    payload: {},
  }),
  ajaxStart: ajax => ({
    type: ActionsTypes.AJAX_START,
    payload: {
      ajax,
    },
  }),
  ajaxDone: ajax => ({
    type: ActionsTypes.AJAX_DONE,
    payload: {
      ajax,
    },
  }),
  ajaxSucceeded: (response, ajax) => ({
    type: ActionsTypes.AJAX_SUCCEEDED,
    payload: {
      response,
      ajax,
    },
  }),
  ajaxFailed: (err, ajax) => ({
    type: ActionsTypes.AJAX_FAILED,
    payload: {
      err,
      ajax,
    },
  }),
  getLoggedUser: () => ({
    type: ActionsTypes.GET_LOGGED_USER,
    payload: {},
  }),
  getConfig: () => ({
    type: ActionsTypes.GET_CONFIG,
    payload: {},
  }),
  getEvent: (city, year) => ({
    type: ActionsTypes.GET_EVENT,
    payload: {
      city,
      year,
    },
  }),
  deleteSerie: (id, callback) => ({
      type: ActionsTypes.DELETE_SERIE,
      payload: {
        id,
        callback
      }
    }),
  getAllCities: () => ({
    type: ActionsTypes.GET_ALL_CITIES,
    payload: {},
  }),
  getAllSeries: () => ({
    type: ActionsTypes.GET_ALL_SERIES,
    payload: {},
  }),
  getSerie: (id) => ({
    type: ActionsTypes.GET_SERIE,
    payload: {
      id
    }
  }),
  getAllSpeakers: () => ({
    type: ActionsTypes.GET_ALL_SPEAKERS,
    payload: {},
  }),
  getAllPartners: () => ({
    type: ActionsTypes.GET_ALL_PARTNERS,
    payload: {},
  }),
};

export default app;
