const initialState = {
  app: {
    isSetup: false,
    inProgressAjaxCount: 0,
  },
  data: {
    serie: null
  },
};

export default initialState;
