import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import {Input} from 'antd';
import {withRouter} from 'react-router-dom';
import ActionCreators from '../state/actions';
import Partner from '../constants/Partner';
import Selector from '../components/Selector';
import {sortData} from '../utils/utils.js'


const SpeakersPage = ({ actions, companies, speakers }) => {
    const [filteredSpeakers, setFilteredSpeakers] = useState([])
    const selectorRef = useRef(null)
    useEffect(() => {
        actions.getAllPartners()
        actions.getAllSpeakers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        setFilteredSpeakers(sortData(speakers))
    }, [speakers])

    const addSpeaker = async (_, speaker, validated, update) => {
        if (!update && validated) {
            setFilteredSpeakers([speaker, ...filteredSpeakers])
        }
    };

    const speakerConfig = {
        id: {
            label: 'id',
            disabled: true,
            valuesForId: ["name"],
            optional: true,
        },
        name: {},
        title: {},
        description: {
            render: <Input.TextArea />,
        },
        company: {
            render: 'selector',
            props: {
                data: companies,
                config: Partner,
                valueField: 'id',
                displayField: 'name',
                endpoint: '/api/partners',
                default: item => item.company
            },
        },
        picture: {
            render: 'imagePicker',
            optional: true,
            props: {
                renameFileTo: (_, formData) => formData.id,
                mode: 'sizes',
                sizes: [100, 190, 500],
                prefix: 'event/speakers',
                authorizedExts: ['jpg'],
            },
        },
    };

    return (
        <Selector
            isModal={false}
            endpoint='/api/speakers'
            _ref={ref => selectorRef.current = ref}
            visible={true}
            value={addSpeaker}
            data={filteredSpeakers}
            config={speakerConfig}
            valueField='id'
            displayField='name'
            id='speaker'
        />);
}

const mapStateToProps = state => {
    return {
        speakers: state.data.speakers,
        companies: state.data.partners
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpeakersPage))

