import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';

extendUpdate(update);

const app = {
  [ActionsTypes.SETUP_APP]: state => update(state, {
    app: {
      isSetup: {$set: true},
    },
  }),
  [ActionsTypes.AJAX_START]: (state) => update(state, {
    app: {
      inProgressAjaxCount: {$set: state.app.inProgressAjaxCount + 1},
    },
  }),
  [ActionsTypes.AJAX_DONE]: (state) => update(state, {
    app: {
      inProgressAjaxCount: {$set: state.app.inProgressAjaxCount - 1},
    },
  }),
  [ActionsTypes.AJAX_SUCCEEDED]: (state, payload) => payload.ajax.target ? update(state, {
    data: {
      [payload.ajax.target]: {$set: payload.response.data},
    }
  }) : state,
};

export default app;
