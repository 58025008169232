let cleanData = ['-', '[', '\'', '\\', '/' ];

export default function(str) {
    var result = str;
    result = result.toLowerCase();
    result = result.replace(/ /g, '-');
    result = result.replace(/'/g, '-');
    result = result.replace(/\./g, '-');
    result = result.replace(/\//g, '-');
    result = result.replace(/\|/g, '-');
    result = result.replace(/--/g, '-');
    result = result.replace(/é/g, 'e');
    result = result.replace(/è/g, 'e');
    result = result.replace(/ë/g, 'e');
    result = result.replace(/ê/g, 'e');
    result = result.replace(/à/g, 'a');
    result = result.replace(/â/g, 'a');
    result = result.replace(/á/g, 'a');
    result = result.replace(/ï/g, 'i');
    result = result.replace(/ì/g, 'i');
    result = result.replace(/î/g, 'i');
    result = result.replace(/ò/g, 'o');
    result = result.replace(/ô/g, 'o');
    result = result.replace(/ö/g, 'o');
    result = result.replace(/õ/g, 'o');
    result = result.replace(/ñ/g, 'n');
    result = result.replace(/ń/g, 'n');
    result = result.replace(/\{/g, '');
    result = result.replace(/\}/g, '');
    result = result.replace(/\[/g, '');
    result = result.replace(/\]/g, '');
    result = result.replace(/\)/g, '');
    result = result.replace(/\(/g, '');
    result = result.replace(/!/g, '');
    result = result.replace(/"/g, '');
    result = result.replace(/,/g, '');
    result = result.replace(/-$/g, '');
    result = result.replace(/^-/g, '');
    result = result.replace(/--/g, '-');

    var check = null;
    var tmp = [];
    while ((check = checkDouble(result)) !== null) {
        tmp = result.split(''); // convert to an array
        tmp.splice(check , 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
        result = tmp.join('');
   }
    return result;
}

function checkDouble(str) {
    for (let i = 0; i !== cleanData.length; i++) {
        for (let d = 0; d !== str.length; d++) {
            if (str[d] === str[d + 1] && str[d] === cleanData[i]) {
                return (d);
            }
        }
    }
    return (null)
}
