import React, {useState} from 'react';
import {Button, Icon, Input, Modal, InputNumber, Select, Switch} from 'antd';
import { useDispatch } from 'react-redux'
import {useHistory} from 'react-router-dom';
import Form from '../../components/Form';
import ActionCreators from '../../state/actions';

const General = ({ serie, onDelete }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [isDeleteVisible, setIsDeleteVisible] = useState(false)

    const toggleDelete = () => {
        setIsDeleteVisible(!isDeleteVisible)
    }
    const handleDeleteConfirmed = () => {
        history.push('/')
        onDelete()
    }

    const handleDelete = () => {
        setIsDeleteVisible(false)
        dispatch(ActionCreators.deleteSerie(serie?._id, handleDeleteConfirmed))
    }

    const form = () => ({
        production: {
            label: 'Display to public',
            render: <Switch defaultChecked={serie?.production} />
        },
        name: {},
        description: {
            render: <Input.TextArea />,
            optional: true,
        },
        year: {
            render: <InputNumber />,
        },
        url: {
            render: <Input type='text'/>,
        },
        language: {
            render:
                <Select initialValue="none">
                    <Select.Option value="fr">FR</Select.Option>
                    <Select.Option value="en">EN</Select.Option>
                    <Select.Option value="es">ES</Select.Option>
                    <Select.Option value="none" disabled>None</Select.Option>
                </Select>
        },
        picture: {
            render: 'imagePicker',
            optional: true,
            label: 'Image',
            hasUpdateName: true,
            props: {
                mode: 'sizes',
                sizes: [400],
                prefix: `serie`,
                authorizedExts: ['jpg'],
                fileUpload: {
                    name: `img-${serie?.name}`,
                    href: serie?.img,
                }
            },
        },
    });


    return (
        <div>
            <Form
                endpoint='/api/series'
                payload={serie || {}}
                config={form()}
                wrapInto={{
                    _id: serie?._id,
                    $this: '$val'
                }}
            />
            <Button style={{float: 'right'}} type='danger' onClick={toggleDelete}>
                <Icon type='delete' /> Delete this serie
            </Button>
            <Modal
                title="Are you sure?"
                visible={isDeleteVisible}
                onOk={handleDelete}
                onCancel={toggleDelete}
            >
                <p>You will delete the serie {serie?.name}.</p>
            </Modal>
        </div>
    )
}

export default General
