import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import ActionCreators from '../state/actions';
import {Col, Select, Button, Divider, Icon} from 'antd';

class Settings extends Component {

  componentDidMount() {
    this.props.actions.getConfig();
    this.props.actions.getAllCities();
  }

  onConfigEdit = (key, value) => this.props.actions.ajaxStart({
    method: 'post',
    endpoint: '/api/config',
    body: { key, value },
    loadingMessage: true,
    successMessage: true,
  });

  regenerateSitemap = () => this.props.actions.ajaxStart({
    method: 'post',
    endpoint: '/api/sitemap',
    body: {},
    successMessage: 'Sitemap generation started',
  });

  reloadLastYears = () => this.props.actions.ajaxStart({
    method: 'post',
    endpoint: '/api/reloadlastyears',
    body: {},
    successMessage: 'Links were updated',
  });

  renderDefaultEventSelect(locale) {
    return (
      <Select
        key={locale}
        style={{minWidth: 150}}
        defaultValue={
          this.props.config.find(c => c._key === 'default_event_' + locale).value
        }
        onChange={value => this.onConfigEdit('default_event_' + locale, value)}
      >
        {this.props.cities.map(city =>
          <Select.Option key={city.city} value={`${city.city}-${city.lastYear}`}>{city.cityName}</Select.Option>
        )}
      </Select>
    );
  }

  render() {
    return (
      <Col offset={10} span={4}>
        {!this.props.config || !this.props.cities ? null : (
          <div>
            <div style={{marginBottom: 5}}>English default event</div>
            {this.renderDefaultEventSelect('en')}

            <div style={{marginBottom: 5, marginTop: 20}}>French default event</div>
            {this.renderDefaultEventSelect('fr')}
          </div>
        )}

        <Divider />
        <Button onClick={this.regenerateSitemap}>
          <Icon type='reload' />
          Regenerate sitemap
        </Button>
        <Button onClick={this.reloadLastYears} style={{ marginTop: 10 }}>
          <Icon type='link' />
          Update event links
        </Button>
      </Col>
    );
  }
}

const mapStateToProps = state => {
  return {
    cities: state.data.cities,
    config: state.data.config && state.data.config.map(c => ({ ...c, _key: c.key })),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
