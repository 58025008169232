import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import ActionCreators from '../state/actions';
import {List, Icon, Input, Modal, Button} from 'antd';
import Form from './Form';

class ListComponent extends Component {

  originalData = (this.props.dataSource || []).map((e, key) => ({...e, key}));

  state = {
    data: this.originalData,
    displayNewLoading: false,
    displayNew: false,
    editing: null,
    editingIndex: null,
    query: null,
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (props.dataSource && props.mainPage) {
      this.originalData = props.dataSource.map((e, key) => ({...e, key}));
      this.filterList(this.state.query || '');
    }
  }

  onOk = (value, apiValidated, update) => {
    this.props.onOk && this.props.onOk(value, this.state.editingIndex, apiValidated, update);
    let newData = this.state.data;
    if (typeof this.state.editingIndex === 'number') {
      newData[this.state.editingIndex] = {
        ...newData[this.state.editingIndex],
        ...value,
      };
    } else {
      newData.push(value);
    }
    this.setState({
      displayNewLoading: false,
      displayNew: false,
      editing: null,
      editingIndex: null,
      data: newData,
    });
  };

  swapUp = (item) => {
    if (item.key === 0)
      return;
    const tmp = this.originalData[item.key - 1];
    this.originalData[item.key - 1] = this.originalData[item.key];
    this.originalData[item.key] = tmp;
    this.originalData = this.originalData.map((e, key) => ({...e, key}));
    this.filterList(this.state.query || '');
    this.props.swapUp(item.key);
  };

  swapDown = (item) => {
    if (item.key + 1 === this.originalData.length)
      return;
    const tmp = this.originalData[item.key + 1];
    this.originalData[item.key + 1] = this.originalData[item.key];
    this.originalData[item.key] = tmp;
    this.originalData = this.originalData.map((e, key) => ({...e, key}));
    this.filterList(this.state.query || '');
    this.props.swapDown(item.key);
  };

  onEdit = (item) => {
    this.setState({
      displayNew: true,
      editing: item,
      editingIndex: item.key,
    });
  };

  onDelete = (item) => {
    const isDbRef = this.props.config && !this.props.onDelete;
    Modal.confirm({
      title: 'Are you sure?',
      content: `You will delete the ${this.props.name} ${item[this.props.displayField]}${isDbRef ? '' : ' from this list'}.`,
      onOk: () => {
        this.originalData.splice(item.key, 1);
        this.originalData = this.originalData.map((e, key) => ({...e, key}));
        this.filterList(this.state.query || '');
        if (isDbRef) {
          this.props.actions.ajaxStart({
            method: 'delete',
            endpoint: `${this.props.endpoint}/${item[this.props.valueField || 'id']}`,
            loadingMessage: this.props.loadingMessage || this.props.loadingMessage !== null,
            successMessage: this.props.successMessage || this.props.successMessage !== null,
          });
        } else {
          this.props.onDelete && this.props.onDelete(item.key);
        }
      },
      onCancel() {},
    });
  };

  onCreate = async () => {
    this.setState({displayNewLoading: true});

    const elem = await this.validate();

    if (!elem) {
      this.setState({
        displayNewLoading: false,
      });
    }
  };

  onCreateCancel = () => {
    this.setState({
      displayNew: false,
      displayNewLoading: false,
      editingIndex: null,
    });
  };

  filterList = async query => {
    const data = this.originalData.filter(e => e[this.props.displayField] && e[this.props.displayField].toLowerCase().indexOf(query.toLowerCase()) > -1);
    this.setState({query, data});
  }

  render() {
    return (
      <div>
        { !this.props.hideSearch ?
          <Input
              type='text'
              prefix={<Icon type='search'/>}
              style={{marginBottom: 20}}
              onChange={e => this.filterList(e.target.value)}
          /> : null
        }
        <div style={{background: '#fefefe'}}>
          <List
            dataSource={this.state.data}
            bordered
            style={this.props.style}
            renderItem={(item, key) => (
              <List.Item>
                <div style={{width: '100%', justifyContent: 'center'}}>
                  <a onClick={() => this.props.onItemClick(item)} style={{flex: 1}}>
                    {this.props.renderItem ? this.props.renderItem(item) : item[this.props.displayField]}
                  </a>
                  <Button style={{float: 'right'}} type='danger' onClick={() => this.onDelete(item, key)}>
                    <Icon type='delete' />
                  </Button>
                  {!this.props.config ? null : (
                    <Button disabled={!!this.props.isEditDisable} style={{float: 'right', marginRight: 5}} onClick={() => this.onEdit(item, key)}>
                      <Icon type='edit' />
                    </Button>
                  )}
                  {!this.props.swapUp ? null : (
                      <Button style={{float: 'right', marginRight: 5}} onClick={() => this.swapUp(item, key)}>
                        <Icon type='up'/>
                      </Button>
                  )}
                  {!this.props.swapDown ? null : (
                      <Button style={{float: 'right', marginRight: 5}} onClick={() => this.swapDown(item, key)}>
                        <Icon type='down'/>
                      </Button>
                  )}
                </div>
              </List.Item>)
            }
            header={this.props.config && !this.props.hideHeader ? (
              <Button disabled={!!this.props.isEditDisable} type="link" onClick={() => this.setState({displayNew: true, editing: null})}>
                <Icon type='plus' /> Create a new {this.props.name}
              </Button>
            ) : null}
          />
        </div>
        {
          this.props.config ? (
          <Modal
          width='70%'
          key={this.state.editing}
          visible={this.state.displayNew}
          closable={false}
          title={<div>{this.state.editing ? 'Edit' : 'Create a new'} {this.props.name}</div>}
          confirmLoading={this.state.displayNewLoading}
          onOk={this.onCreate}
          onCancel={this.onCreateCancel}
          >
          <Form
          endpoint={this.props.endpoint}
          loadingMessage={null}
          successMessage={null}
          payload={this.state.editing ? this.state.editing : {}}
          config={this.props.config}
          substituteValidation={ref => this.validate = ref}
          disableApiValidation={this.props.disableApiValidation}
          postSubmit={this.onOk}
          isEditing={!!this.state.editing}
          valueId={!this.state.editing ? String(this.originalData.length) : null}
          />
          </Modal>
          ) : null
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListComponent);
