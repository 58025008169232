import {put, takeEvery} from 'redux-saga/effects';
import ActionsTypes from '../../constants/ActionsTypes';
import ActionsCreators from '../actions';
import {notification, message} from 'antd';
import api from '../../services/api';
import getState from '../selectors';

let hideLoader = null;
let loaderCount = 0;

const ajaxStart = function* (action) {
  const msg = action.payload.ajax.loadingMessage;
  if (!loaderCount && msg) {
    hideLoader = message.loading(typeof msg === 'string' ? msg : 'Loading...', 0);
    loaderCount += 1;
  } else if (msg) {
    loaderCount += 1;
  }

  try {
    const state = yield getState();
    const res = yield api(action.payload.ajax.method, action.payload.ajax.endpoint, action.payload.ajax.body, state);

    yield put(ActionsCreators.ajaxSucceeded(res, action.payload.ajax));
  } catch (error) {
    yield put(ActionsCreators.ajaxFailed(error, action.payload.ajax));
  }
};

const ajaxDone = function* (action) {
  const msg = action.payload.ajax.loadingMessage;
  if (msg) {
    loaderCount -= 1;
    if (loaderCount <= 0) {
      hideLoader();
    }
  }
  if (typeof action.payload.ajax.callback === 'function') {
    action.payload.ajax.callback();
  };

  yield null;
}

const ajaxFailed = function* (action) {
  yield put(ActionsCreators.ajaxDone(action.payload.ajax));
  notification.error({
    message: 'Request failed',
    description: action.payload.err.toString(),
  });
};

const ajaxSucceeded = function* (action) {
  yield put(ActionsCreators.ajaxDone(action.payload.ajax));
  const msg = action.payload.ajax.successMessage;
  if (msg) {
    message.success(typeof msg === 'string' ? msg : 'Saved');
  }
  // const state = yield getState();
  // if (state.event) {
  // yield put(ActionsCreators.getEvent(state.event.city.city, state.event.year));
  // }
};

const getLoggedUser = function* () {
  yield put(
    ActionsCreators.ajaxStart({
      endpoint: `/api/me`,
      target: 'user',
      loadingMessage: true,
    }),
  );
};

const getConfig = function* (action) {
  yield put(
    ActionsCreators.ajaxStart({
      endpoint: '/api/config',
      target: 'config',
      loadingMessage: true,
    }),
  );
};

const getEvent = function* (action) {
  yield put(
    ActionsCreators.ajaxStart({
      endpoint: `/api/events/${action.payload.city}/${action.payload.year}`,
      target: 'event',
      loadingMessage: true,
    }),
  );
};

const getSerie = function* (action) {
    yield put(
        ActionsCreators.ajaxStart({
            endpoint: `/api/series/${action.payload.id}`,
            target: 'serie',
            loadingMessage: true,
        }),
    );
};
const deleteSerie = function* (action) {
    yield put(
        ActionsCreators.ajaxStart({
            method: 'delete',
            endpoint: `/api/series/${action.payload.id}`,
            loadingMessage: true,
            successMessage: true,
            callback: action.payload.callback
        }),
    );
};

const getAllCities = function* (action) {
  yield put(
    ActionsCreators.ajaxStart({
      endpoint: `/api/cities?all=true`,
      target: 'cities',
      loadingMessage: true,
    }),
  );
};

const getAllPartners = function* (action) {
  yield put(
    ActionsCreators.ajaxStart({
      endpoint: `/api/partners`,
      target: 'partners',
      loadingMessage: true,
    }),
  );
};

const getAllSpeakers = function* (action) {
  yield put(
    ActionsCreators.ajaxStart({
      endpoint: `/api/speakers`,
      target: 'speakers',
      loadingMessage: true,
    }),
  );
};

const getAllSeries = function* (action) {
    yield put(
        ActionsCreators.ajaxStart({
            endpoint: `/api/series`,
            target: 'series',
            loadingMessage: true,
        }),
    );
};

const sagas = function* () {
  yield takeEvery(ActionsTypes.AJAX_START, ajaxStart);
  yield takeEvery(ActionsTypes.AJAX_DONE, ajaxDone);
  yield takeEvery(ActionsTypes.AJAX_FAILED, ajaxFailed);
  yield takeEvery(ActionsTypes.AJAX_SUCCEEDED, ajaxSucceeded);
  yield takeEvery(ActionsTypes.GET_EVENT, getEvent);
    yield takeEvery(ActionsTypes.GET_SERIE, getSerie);
    yield takeEvery(ActionsTypes.DELETE_SERIE, deleteSerie);
  yield takeEvery(ActionsTypes.GET_ALL_SPEAKERS, getAllSpeakers);
  yield takeEvery(ActionsTypes.GET_ALL_CITIES, getAllCities);
    yield takeEvery(ActionsTypes.GET_ALL_SERIES, getAllSeries);
  yield takeEvery(ActionsTypes.GET_ALL_PARTNERS, getAllPartners);
  yield takeEvery(ActionsTypes.GET_LOGGED_USER, getLoggedUser);
  yield takeEvery(ActionsTypes.GET_CONFIG, getConfig);
};

export default sagas;
