import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import ActionCreators from '../state/actions';
import {Input, Col, Select} from 'antd';
import List from '../components/List';

class AgendaComponent extends Component {

  state = {
    agenda: this.props.event && this.props.event.agenda ? this.props.event.agenda : [],
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (props.event) {
      this.setState({agenda: props.event.agenda || []});
    }
  }

  sendToApi = payload => {
    this.props.actions.ajaxStart({
      method: 'post',
      endpoint: '/api/events',
      body: {
        ...payload,
        city: this.props.event.city.city,
        year: this.props.event.year,
      },
      loadingMessage: true,
      successMessage: true,
      // callback: this.props.postSubmit && this.props.postSubmit.bind(null, payload),
    });
  }

  deletePresentation = (index) => {
    this.state.agenda.splice(index, 1);
    this.sendToApi({
      agenda: this.state.agenda.map(p => ({...p, speaker : p.speaker ? p.speaker.id ? p.speaker.id : typeof p.speaker === "string" ? p.speaker : p.speaker.map(s => (typeof s === "object" ? s.id : s)) : []}))
    });
  };

  swapUp = (index) => {
    const tmp = this.state.agenda[index - 1];
    var result = this.state.agenda;
    result[index - 1] = this.state.agenda[index];
    result[index] = tmp;
    this.setState({
      agenda: result
    })
    this.sendToApi({
      agenda: this.state.agenda,
    });
  };

  swapDown = (index) => {
    const tmp = this.state.agenda[index + 1];
    var result = this.state.agenda;
    result[index + 1] = this.state.agenda[index];
    result[index] = tmp;
    this.setState({
      agenda: result
    })
    this.sendToApi({
      agenda: this.state.agenda,
    });
  };

  addPresentation = (presentation, updateIndex) => {
    if (!presentation) {
      return;
    }
    if (presentation.speaker && presentation.speaker.lenght !== 0) {
      presentation.speaker = presentation.speaker.map( p => (p.id ? p.id : p))
    }
    let newAgenda = this.state.agenda.map(p => ({...p, speaker : p.speaker ? p.speaker.id ? p.speaker.id : typeof p.speaker === "string" ? p.speaker : p.speaker.map(s => (typeof s === "object" ? s.id : s)) : []}));
    if (typeof updateIndex !== 'number') {
      newAgenda = newAgenda.concat([presentation]);
    } else {
      newAgenda[updateIndex] = presentation;
    }
    this.setState({
      agenda: newAgenda,
    });
    this.sendToApi({
      agenda: newAgenda,
    });
  };

  render() {
    const presentation = {
      type: {
        render: (
          <Select>
            <Select.Option value="text">Text</Select.Option>
            <Select.Option value="horizontal">Horizontal</Select.Option>
            <Select.Option value="vertical">Vertical</Select.Option>
          </Select>
        ),
      },
      time: {
        render: (
            <Input type={"text"} />
        ),
      },
      title: {},
      style: {
        render: (
          <Select>
            <Select.Option value="primary">Primary</Select.Option>
            <Select.Option value="secondary">Secondary</Select.Option>
          </Select>
        ),
      },
      speaker: this.props.event ? {
        optional: true,
        render: 'multiSelector',
        props: {
          data: this.props.event.speakers,
          valueField: 'id',
          displayField: 'name',
        },
      } : null,
      description: {
        render: <Input.TextArea />,
        optional: true,
      },
    };
    
    return (
      <Col offset={6} span={12}>
        <List
          dataSource={this.state.agenda}
          displayField='title'
          onItemClick={console.info}
          config={presentation}
          name='presentation'
          disableApiValidation={true}
          onOk={this.addPresentation}
          onDelete={this.deletePresentation}
          swapUp={this.swapUp}
          swapDown={this.swapDown}
          mainPage={true}
        />
      </Col>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgendaComponent);
