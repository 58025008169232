import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import ActionCreators from '../state/actions';
import {Select, Col, Button, Icon, Divider} from 'antd';
import List from '../components/List';
import Selector from '../components/Selector';
import Partner from '../constants/Partner';
import {sortData} from '../utils/utils.js'

class SponsorsComponent extends Component {

  state = {
    sponsors: this.props.event && this.props.event.sponsors ? this.props.event.sponsors : null,
    sponsorLevels: this.props.event && this.props.event.sponsorLevels ? this.props.event.sponsorLevels : null,
  }

  componentDidMount() {
    this.props.actions.getAllPartners();
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.event) {
      this.setState({
        sponsors: props.event.sponsors || [],
        sponsorLevels: props.event.sponsorLevels || [],
      });
    }
  }

  sendToApi = (payload, callback) => {
    this.props.actions.ajaxStart({
      method: 'post',
      endpoint: '/api/events',
      body: {
        ...payload,
        city: this.props.event.city.city,
        year: this.props.event.year,
      },
      loadingMessage: true,
      successMessage: true,
      callback,
    });
  }

  addSponsor = async (_, sponsor) => {
    if (!sponsor) {
      return;
    }
    await this.setState({sponsors: [...this.state.sponsors, sponsor]});
    this.sendToApi({
      sponsors: this.state.sponsors.map(s => ({level: s.level, id: s.id})),
    }, () => {
      this.props.actions.getAllPartners();
      this.props.actions.getEvent(this.props.event.city.city, this.props.event.year);
    });
  };

  deleteSponsor = (index) => {
    this.state.sponsors.splice(index, 1);
    this.sendToApi({
      sponsors: this.state.sponsors.map(s => ({level: s.level, id: s.id})),
    });
  };

  addSponsorLevel = async (level, index) => {
    const sponsorLevels = this.state.sponsorLevels || [];

    if (typeof index === 'number') {
      sponsorLevels[index] = {
        ...sponsorLevels[index],
        ...level,
      };
    } else {
      sponsorLevels.push(level);
    }

    await this.setState({sponsorLevels});
    this.sendToApi({sponsorLevels});
  };

  deleteSponsorLevel = (index) => {
    this.state.sponsorLevels.splice(index, 1);
    this.sendToApi({
      sponsorLevels: this.state.sponsorLevels,
    });
  };

  render() {
      return (
      <Col offset={6} span={12}>
        <h3>Sponsor levels</h3>
        <List
          dataSource={sortData(this.state.sponsorLevels, 'title')}
          displayField='title'
          onItemClick={console.info}
          name='sponsor level'
          endpoint='/api/events'
          disableApiValidation={true}
          mainPage={true}
          onOk={this.addSponsorLevel}
          onDelete={this.deleteSponsorLevel}
          config={{
            title: {},
            color: {
              label: 'Color and size',
              render: (
                <Select>
                  <Select.Option value='title'>Green</Select.Option>
                  <Select.Option value='gold'>Gold</Select.Option>
                  <Select.Option value='silver'>Silver</Select.Option>
                  <Select.Option value='bronze'>Bronze</Select.Option>
                  <Select.Option value='startup'>Start-up green</Select.Option>
                </Select>
              ),
            },
            // size: {
            //   render: (
            //     <Select>
            //       <Select.Option value='title'>Big</Select.Option>
            //       <Select.Option value='-'>Normal</Select.Option>
            //       <Select.Option value='m'>Little</Select.Option>
            //       <Select.Option value='s'>Very little</Select.Option>
            //     </Select>
            //   ),
            // },
            itemsPerRow: {
              isNumber: true,
              label: 'Items per row',
              render: (
                <Select>
                  <Select.Option value='3'>3</Select.Option>
                  <Select.Option value='2'>2</Select.Option>
                </Select>
              ),
            },
          }}
        />
        <Divider />
        <h3>Sponsors</h3>
        <List
          endpoint='/api/events'
          dataSource={sortData(this.state.sponsors)}
          displayField='name'
          onItemClick={console.info}
          onDelete={this.deleteSponsor}
          name='sponsor'
          mainPage={true}
        />
        <Button onClick={() => this.selector.show()} style={{marginTop: 10}}>
          <Icon type='plus' /> Add or edit sponsor
        </Button>
        <Selector
          endpoint='/api/partners'
          _ref={ref => this.selector = ref}
          value={this.addSponsor}
          data={sortData(this.props.partners)}
          config={Partner}
          valueField='id'
          displayField='name'
          id='sponsor'
          customizationConfig={{
            level: {
              render: (
                <Select>
                  {this.state.sponsorLevels && this.state.sponsorLevels.map((level, key) => (
                    <Select.Option value={level.title} key={key}>{level.title}</Select.Option>
                  ))}
                </Select>
              ),
            },
          }}
        />
      </Col>
    );
  }
}

const mapStateToProps = state => {
  return {
    partners: state.data.partners,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SponsorsComponent);
