import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import ActionCreators from '../state/actions';
import {withRouter} from 'react-router-dom';
import TitleYoutube from '../document/title_youtube.png'
import SinginYoutube from '../document/signin_youtube.png'
import UploadYoutube from '../document/upload_video_youtube.png'
import SwitchAccountYoutube from '../document/switch_account_youtube.png'
import {message} from "antd";

class VideoComponent extends Component {

    sendPdfToApi = video => {
        this.props.actions.ajaxStart({
            method: 'post',
            endpoint: '/api/video',
            body: {
                key: "video",
                value: "update"
            },
            onSuccess: message.success("Update Videos"),
        })
    }

    renderEN() {
        return (
            <div>
                <h1 style={{textAlign:"center"}}>Upload video on Youtube</h1>
                All the videos on the Vintage Report Youtube channel are visible on the TV section of the VR website.<br/>
                To properly assign a video to a specific event, you need to follow the naming convention described below for the title.
                <table style={{borderCollapse:"separate", borderSpacing:"0 15px"}}>
                    <tbody>
                        <tr style={{paddingBottom: "100px"}}>
                            <td>
                                <img style={{maxHeight: "500px", maxWidth: "400px"}} src={SinginYoutube}></img>
                            </td>
                            <td>Go on <a href="http://youtube.com" target="_blank">youtube</a> and click on connection. Sign in with your fruition google account.<br/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img style={{maxHeight: "500px", maxWidth: "400px"}} src={SwitchAccountYoutube}></img>
                            </td>
                            <td>
                                AT the top right corner, click on your picture, then “switch account” then select the “Vintage Report” Brand account.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img style={{maxHeight: "500px", maxWidth: "400px"}} src={UploadYoutube}></img>
                            </td>
                            <td>
                                At the top right, select Create a video or post and then Upload video.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img style={{maxHeight: "500px", maxWidth: "400px"}}src={TitleYoutube}/>
                            </td>
                            <td valign="top" align="left">
                                <div style={{color:"red"}}>You should follow this syntax for the title of the video :</div><br/>
                                <div> <div style={{paddingLeft:"5em"}}>EN : [Year] [City] Vintage Report-[Speaker Name]-[Title]<br/></div><i>exemple : 2019 Napa Vintage Report - Mark Anderson - Conclusion</i></div>
                                <div> <div style={{paddingLeft:"5em"}}>FR : [Année] [Ville] Conference du Millesime-[Nom intervenant]-[Titre]<br/></div><i>exemple : 2019 Bordeaux Conference du Millesime - Mark Anderson - Conclusion</i></div>
                                <p><br/>- If you want a video to be assigned to a specific event on the TV page (via the search field), [city] and [year] are required in the title.</p>
                                <p>- To display the "summary" video on a specific event, [city], [year] and [Résumé] or [Summary] are required in the title.<br/><i>exemple : 2019 Napa Vintage Report - Summary</i></p>
                                <p>The video will be display the next day on the web site after his upload on youtube.<br/>If you want it to appear directly you have an update button at the top of the page.<br/>A good description with proper keywords (the speaker, the subject, …) is important for SEO.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        return (
            <div>
                <button style={{padding: "5px 5px", marginLeft:"5px"}} onClick={this.sendPdfToApi}>{"Update Videos"}</button>
                {this.renderEN()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        cities: state.data.cities,
        config: state.data.config && state.data.config.map(c => ({ ...c, _key: c.key })),
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoComponent));
