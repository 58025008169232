import React, {Component} from 'react';
import {Form} from 'antd';

class FormItemComponent extends Component {

	state = {
		initialValue: this.props.initialValue,
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (this.state.initialValue !== props.initialValue) {
			this.setState({ initialValue: props.initialValue });
			this.props.form.resetFields();
		}
	}

	render() {
		const {
			_key: key,
			field,
			children,
			selectorError,
			initialValue,
			extensionTitle,
			form: {getFieldDecorator},
			...rest
		} = this.props;
		const optional = typeof this.props.optional === 'undefined' ? field.optional : this.props.optional;
		const formItemLayout = {
			labelCol: {
				xs: {span: 24},
				sm: {span: 8},
			},
			wrapperCol: {
				xs: {span: 24},
				sm: {span: 8},
			},
		};
		return (
			<Form.Item
				key={key}
				label={field.label || key[0].toUpperCase() + key.substr(1) + (extensionTitle ? extensionTitle: "")}
				{...formItemLayout}
				{...rest}
			>
				{getFieldDecorator(key, {
					rules: [{required: !optional, message: field.help}],
					initialValue,
				})(children)}
			</Form.Item>
		);
	}
}

export default FormItemComponent;
