import React, {useEffect} from 'react';
import {Tabs, Icon} from 'antd';
import { useParams} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import ActionCreators from '../../state/actions';
import Webinars from './Webinars';
import General from './General';

const SeriePage = ({ onDelete }) => {
    const dispatch = useDispatch()
    const { serie } = useSelector(state => state.data)
    const { id } = useParams()

    useEffect(() => {
        if (id) {
            dispatch(ActionCreators.getSerie(id))
        }
    }, [dispatch, id])

    return  (
        <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={<span><Icon type="setting" />General</span>} key="1">
                <General serie={serie} onDelete={onDelete} />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!serie} tab={<span><Icon type="schedule" />Webinars</span>} key="2">
                <Webinars serie={serie} webinars={serie?.webinars || []} />
            </Tabs.TabPane>
        </Tabs>
    )
}

export default SeriePage


