import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import ActionCreators from '../state/actions';
import {Divider, Col} from 'antd';
import List from '../components/List';
import FilePicker from '../components/FilePicker'

class PhotosComponent extends Component {

  state = {
    photos: [],
  }

  componentDidMount() {
    this.setPhotos(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.event) {
      this.setPhotos(props);
    }
  }

  setPhotos = props => this.setState({
    photos: props.event.photos ? props.event.photos.map((p, key) => ({...p, key})) : [],
  });

  renameFileTo = filename => {
    if (this.props.event.city.locale === 'fr_FR') {
      return `conference-du-millesime-${this.props.event.city.city}-${this.props.event.year}-${filename}`;
    } else {
      return `vintage-report-${this.props.event.city.city}-${this.props.event.year}-${filename}`;
    }
  }

  onBunchUpload = info => {
    const filenameSplit = info.file.name.split('.');
    const ext = filenameSplit.pop();
    const filename = this.renameFileTo(filenameSplit.join('.').replace(/ /g, ''));
    const fullname = `${this.props.event.city.city}/${this.props.event.year}/${filename}.${ext}`;
    const photo = {
      uri: fullname,
      title: `Vintage Report ${this.props.event.city.cityName} ${this.props.event.year}`,
    };

    this.setState({
      photos: [...this.state.photos, photo],
    });
    this.props.actions.ajaxStart({
      method: 'post',
      endpoint: '/api/events/photos',
      body: {
        city: this.props.event.city.city,
        year: this.props.event.year,
        photo,
      },
      loadingMessage: true,
    });
  }

  sendPhotosToApi = photos => {
    const photosForApi = this.state.photos.map(p => ({
      uri: p.uri,
      title: p.title,
      displayOnEventHome: p.displayOnEventHome || false
    }));

    this.props.actions.ajaxStart({
      method: 'post',
      endpoint: '/api/events',
      body: {
        city: this.props.event.city.city,
        year: this.props.event.year,
        photos: photosForApi,
      },
      loadingMessage: true,
    });
  }

  updatePhoto = async (photo, index) => {
    await this.setState({
      photos: this.state.photos.map((p, idx) => ({
        ...p,
        title: idx === index ? photo.title : p.title,
        displayOnEventHome: idx === index ? photo.displayOnEventHome : p.displayOnEventHome,
      })),
    });

    this.sendPhotosToApi(this.state.photos);
  }

  deletePhoto = async index => {
    await this.setState({
      photos: [
        ...this.state.photos.slice(0, index),
        ...this.state.photos.slice(index + 1),
      ],
    });

    this.sendPhotosToApi(this.state.photos);
  }

  renderPhoto(photo) {
    return (
      <span>
        <Col span={3}>
          <img src={photo.src} style={{'maxHeight': '60px', 'maxWidth': '80px'}} alt='' />
        </Col>
        <Col span={17} style={{paddingTop: 6}}>
          {photo.title}
          <br />
          <span style={{color: 'grey', textOverflow: 'ellipsis', maxWidth: 60}}>
            {photo.uri.split('/').pop()}
          </span>
        </Col>
      </span>
    );
  }

  render() {
    return (
      <div>
        <FilePicker
          apiRoute='/api/images'
          renameFileTo={this.renameFileTo}
          prefix={`event/${this.props.event.city.city}/${this.props.event.year}`}
          mode='thumbnail'
          authorizedExts={['jpg']}
          onSuccess={this.onBunchUpload}
          hint='Photos are processed asynchronously so thumbnails will not appear unless you refresh after a few seconds.'
        />
        <Divider />
        <Col offset={6} span={12}>
          <List
            hideHeader={true}
            renderItem={this.renderPhoto}
            valueField= {'uri'}
            displayField='uri'
            dataSource={this.state.photos}
            onItemClick={console.info}
            name='photo'
            mainPage={true}
            onDelete={this.deletePhoto}
            onOk={this.updatePhoto}
            disableApiValidation={true}
            config={{
              title: {},
              displayOnEventHome: {
                label: 'Display on event home page',
                render: 'checkbox',
              },
            }}
          />
        </Col>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotosComponent);
