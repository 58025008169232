const axios = require('axios');

export default async function(method, endpoint, data, state) {
	try {
		return await axios({
			url: `${process.env.REACT_APP_API_URL}${endpoint}`,
			method: method || 'get',
			data,
			headers: {
				'content-type': 'application/json',
			},
			withCredentials: true,
		}, {});
	} catch (error) {
		if (error.response && error.response.status === 401) {
			window.location.replace(`${process.env.REACT_APP_API_URL}/api/auth`);
		} else {
			throw error;
		}
	}
}
