import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import ActionCreators from '../state/actions';
import {Modal} from 'antd';
import List from './List';
import Form from './Form';

class SelectorComponent extends Component {
  state = {
    visible: false,
    value: null,
    isEditing: false,
  };

  componentDidMount() {
    this.update(this.props);
    this.props._ref(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.data && !this.props.data) {
      this.update(props);
    }
  }

  update = (props) => {
    let value;
    let defaultValue = this.props.default;

    if (typeof defaultValue === 'function') {
      defaultValue = defaultValue(this.props.item);
    }
    if (defaultValue) {
      value = (props.data || []).find(e => e[this.props.valueField] === defaultValue[this.props.valueField]);
      this.setState({value});
      this.props.value(this.props.id, value);
    }
  }

  clear = () => {
    this.setState({value: null});
    this.props.value(this.props.id, null);
  }

  show = () => this.setState({visible: true});

  postSubmit = (values, apiValidated) => {
    this.props.value(this.props.id, {
      ...this.state.value,
      ...values,
    }, apiValidated);
  }

  onCustomizationOk = async () => {
    this.setState({
      customizationVisible: false,
    });

    if (typeof this.props.value === 'function') {
      this.validate();
    }
  }

  onOk = async (value, _, apiValidated, update) => {
    await this.setState({
      visible: false,
      value,
      isEditing : update,
    });

    if (this.props.customizationConfig) {
      this.setState({customizationVisible: true});
    } else if (typeof this.props.value === 'function') {
      this.props.value(this.props.id, value, apiValidated, update);
    }
  };

  onCancel = () => {
    this.setState({
      visible: false,
      customizationVisible: false,
    });
  };

  renderList = () => (<List
      endpoint={this.props.endpoint}
      dataSource={(this.props.data || [])}
      displayField={this.props.displayField}
      valueField={this.props.valueField}
      onItemClick={item => this.onOk(item)}
      config={this.props.config}
      name={this.props.id}
      style={{maxHeight: this.props.isModal ? 500 : '80vh', overflowY: 'scroll'}}
      onOk={this.onOk}
  />)

  render() {
    const { isModal, id, customizationConfig} = this.props
    return (
      <div>
        {isModal ? (<Modal
            visible={this.state.visible}
            closable={false}
            title={<div>Select a {id}</div>}
            footer={null}
            onCancel={this.onCancel}
        >
          {this.renderList()}
        </Modal>) : this.renderList()}
        {
          !customizationConfig || this.state.isEditing ? null : (
            <Modal
              visible={this.state.customizationVisible}
              closable={false}
              title={<div>Customize {id}</div>}
              onOk={this.onCustomizationOk}
              onCancel={this.onCancel}
            >
              <Form
                loadingMessage={null}
                successMessage={null}
                payload={{[Object.keys(customizationConfig).find(k => customizationConfig[k] === null)]: this.state.value}}
                config={customizationConfig}
                substituteValidation={ref => this.validate = ref}
                disableApiValidation={true}
                postSubmit={this.postSubmit}
                isEditing={this.state.customizationVisible}
              />
            </Modal>
          )
        }
      </div>
    );
  }
}

SelectorComponent.defaultProps = {
  isModal: true,
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectorComponent);
