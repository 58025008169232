import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import ActionCreators from '../state/actions';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Layout from './Layout';
import 'antd/dist/antd.css';

class App extends Component {
  componentDidMount() {
    this.props.actions.setupApp();
  }

  render() {
    return (
      <Router className="App">
        <Route path='/' component={Layout} />
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSetup: state.app.isSetup
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
