import React, {Component} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import ActionCreators from '../state/actions';
import {Input, Col, Button, Icon} from 'antd';
import List from '../components/List';
import Partner from '../constants/Partner';
import Selector from '../components/Selector';
import {sortData} from '../utils/utils.js'

class SpeakersComponent extends Component {

  state = {
    speakers: this.props.event && this.props.event.speakers ? this.props.event.speakers : [],
    showSelector: false
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      props : props,
      speakers: props.event && props.event.speakers ? props.event.speakers : [],
    };
  }

  componentDidMount() {
    this.props.actions.getAllSpeakers();
    if (!this.props.companies) {
      this.props.actions.getAllPartners();
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.event) {
      this.setState({speakers: props.event.speakers || []});
    }
  }

  sendToApi = (payload, callback) => {
    this.props.actions.ajaxStart({
      method: 'post',
      endpoint: '/api/events',
      body: {
        ...payload,
        city: this.props.event.city.city,
        year: this.props.event.year,
      },
      loadingMessage: true,
      successMessage: true,
      callback,
    });
  }

  deleteSpeaker = (index) => {
    this.state.speakers.splice(index, 1);
    this.sendToApi({
      speakers: this.state.speakers.map(s => s.id),
    });
  };

  addSpeaker = async (_, speaker, __, update) => {
    if (!speaker) {
      return;
    }
    if (!update) {
      await this.setState({speakers: [...this.state.speakers, speaker]});
    }
    this.sendToApi({
      speakers: this.state.speakers.map(s => s.id),
    }, () => {
      this.props.actions.getAllSpeakers();
      this.props.actions.getEvent(this.props.event.city.city, this.props.event.year);
    });
  };

  render() {
    const speaker = {
      id: {
        label: 'id',
        disabled: true,
        valuesForId: ["name"],
        optional: true,
      },
      name: {},
      title: {},
      description: {
        render: <Input.TextArea />,
      },
      company: {
        render: 'selector',
        props: {
          data: this.props.companies,
          config: Partner,
          valueField: 'id',
          displayField: 'name',
          endpoint: '/api/partners',
          default: item => item.company
        },
      },
      picture: {
        render: 'imagePicker',
        optional: true,
        props: {
          renameFileTo: (_, formData) => formData.id,
          mode: 'sizes',
          sizes: [100, 190, 500],
          prefix: 'event/speakers',
          authorizedExts: ['jpg'],
        },
      },
    };
    return (
      <Col offset={6} span={12} data-test="speakersComponent">
        <List
          dataSource={sortData(this.state.speakers)}
          displayField='name'
          onItemClick={console.info}
          name='speaker'
          onDelete={this.deleteSpeaker}
          mainPage={true}
        />
        <Button onClick={() => this.selector.show()} style={{marginTop: 10}}>
          <Icon type='plus' /> Add or edit speaker
        </Button>
        <Selector
          endpoint='/api/speakers'
          _ref={ref => this.selector = ref}
          visible={this.state.showSelector}
          value={this.addSpeaker}
          data={sortData(this.props.speakers)}
          config={speaker}
          valueField='id'
          displayField='name'
          id='speaker'
        />
      </Col>
    );
  }
}

const mapStateToProps = state => {
  return {
    speakers: state.data.speakers,
    companies: state.data.partners,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpeakersComponent);
