import React, {Component} from 'react';
import {Icon, Button} from 'antd';

class FilePickerRender extends Component {

    render() {
        return (
            this.props.href ?
                <div style={{width: '100%', justifyContent: 'center'}}>
                    {	this.props.displayPicture === undefined || this.props.displayPicture === true?
                        <img style={{'maxHeight': '60px', 'maxWidth': '80px', marginRight: 5}} src={this.props.href} alt={''}/>
                        : null
                    }
                    {this.props.name &&
                    <a style={{flex: 1}} href={this.props.href}>
                        {this.props.name}
                    </a>}
                    { this.props.onDelete ?
                        <Button style={{float: 'right'}} type='danger' onClick={() => this.props.onDelete(this.props)}>
                            <Icon type='delete'/>
                        </Button>
                        : null
                    }
                </div>
            : null
        );
    }
}

export default FilePickerRender;
